import React, { useState, useEffect, useRef } from 'react';
import './Typewriter.css'; // Import the CSS file
import parse from 'html-react-parser';

const Typewriter = ({ text, speed = 100, onComplete }) => {
  const [displayedText, setDisplayedText] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const textIndex = useRef(0);
  const intervalId = useRef(null);

  // Function to parse displayed text into JSX elements with animation
  const parseDisplayedText = (textArray) => {
    // Join the elements without commas, maintaining the correct structure
    const joinedText = textArray.map((char, index) => {

      // console.log(char);
      // print(index);
      if (char === ' ') {
        return <span key={index} className="fade-in">&nbsp;</span>;
      } else if (char.includes( '<li>')) {
        return <div key={index} >{parse(char)}</div>;
      } else if (char.includes( '<ul>')) {
        return <ul key={index}/>;
      } else if (char.includes( '</ul>')) {
        return <ul key={index}/>;
      } else if (char.includes( '<\li>')) {
        return <li key={index} >{parse(char)}</li>
      } else {
        return <p key={index} className="fade-in">{parse(char)}</p>;
      }
    });
  
    // Render the joined elements inside a <p> tag
    return joinedText;
  };
  

  useEffect(() => {
    // Typing logic
    const type = () => {
      if (textIndex.current < currentText.length) {
        setDisplayedText((prevDisplayedText) => {
          // Find the next space or end of text to type the whole word
          let nextSpaceIndex = currentText.indexOf(' ', textIndex.current);
          if (nextSpaceIndex === -1) nextSpaceIndex = currentText.length;
          const newText = currentText.slice(textIndex.current, nextSpaceIndex) + " ";
          
          // Move the index forward
          textIndex.current = nextSpaceIndex + 1; // Skip past the space

          // Check for completion condition
          if (newText.includes('[') && onComplete) {
            cancelAnimationFrame(intervalId.current);
            onComplete();
          }

          return [...prevDisplayedText, newText];
        });
      }
    };

    const step = () => {
      type();
      intervalId.current = setTimeout(step, speed);
    };

    intervalId.current = setTimeout(step, speed);

    return () => clearTimeout(intervalId.current);
  }, [currentText, speed, onComplete]);

  useEffect(() => {
    if (text.length > displayedText.length) {
      setCurrentText(text);
    }
  }, [text]);

  return (
    <div className="typewriter">
      {parseDisplayedText(displayedText)}
    </div>
  );
};

export default Typewriter;

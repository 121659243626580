import React, { Suspense, useEffect, useState, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./Assets/Fonts/stylesheet.css";
import "./Assets/NewFonts/style.css";

import { Route, Routes, useNavigate } from 'react-router-dom';
import "rc-slider/assets/index.css";
import { Toaster } from "react-hot-toast";
import "@rainbow-me/rainbowkit/styles.css";
import "swiper/css";
import "swiper/css/effect-cards";
import {
  RainbowKitProvider,
  connectorsForWallets,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  trustWallet,
  ledgerWallet,
  argentWallet,
  walletConnectWallet,
  okxWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { mainnet, polygon, bsc, manta, baseSepolia, optimismSepolia, arbitrumSepolia, base, coreDao, optimism, bscTestnet } from '@wagmi/core/chains'

import DocumentMeta from "react-document-meta";
import { useDispatch, useSelector } from "react-redux";
import { setChatbotLanguage, updateUserData, userDataSelector } from "./Redux/Slices/userDataSlice";
import User from "./Models/User";
import HomePage from "./Pages/HomePage/HomePage";
import ExplorePage from "./Pages/ExplorePage/ExplorePage";
import ChatbotsPage from "./Pages/ChatbotsPage/ChatbotsPage";
import CreateAICompanionPage from "./Pages/CreateAICompanionPage/CreateAICompanionPage";
import ChatScreenPage from "./Pages/ChatScreenPage/ChatScreenPage";
import UserProfile from "./Pages/UserProfilePage/UserProfile";
import LeftSidebar from "./Components/LeftSidebar/LeftSidebar";
import { LoginPopUp } from "./Components/PopUps/LoginPopup/LoginPopUp";
import Header from "./Components/Header/Header";
import PricingPage from "./Pages/PricingPage/PricingPage";
import "./App.css";
import CongratulationsPage from "./Pages/CongratulationsPage/CongratulationsPage";
import {
  CheckTransaction,
  DownloadData,
  DownloadSubInfo,
  HandleAuth,
  HandleObiTransaction,
} from "./Utils/UserUtils";
import { Image } from "react-bootstrap";
import OnboardingPopup from "./Components/PopUps/OnboardingPopup/OnboardingPopup";
import ExplorePageCategories from "./Pages/ExplorePageCategories/ExplorePageCategories";
import { motion, AnimatePresence } from "framer-motion";
import AiGenratePage from "./Pages/AiGenratePage/AiGenratePage";
import SearchChatbot from "./Pages/SearchChatbot/SearchChatbot";
import Loader from "./Components/Loader/Loader";
import ChatbotsPageList from "./Pages/ChatbotsPageList/ChatbotsPageList";
import CategoriesBigSubcategories from './Components/CategoriesBigBox/CategoriesBigSubcategories';
import CategoryChatbots from "./Pages/CategoryChatbots/CategoryChatbots";
import AllCategoriesBigBox from "./Pages/CategoriesBox/AllCategoriesBigBox";
import { Icon } from "@iconify/react";
import { languageOptions } from "./Utils/LanguageList";
import MobileNavigation from "./Components/MobileNavigation/MobileNavigation";
import MychatbotPage from "./Pages/MychatbotPage/MychatbotPage";

let isDirty = false;
export function SetDirty() {
  isDirty = true;
}
// const HomePage = React.lazy(() => import("./Pages/HomePage/HomePage"));
// const ExplorePage = React.lazy(() => import("./Pages/ExplorePage/ExplorePage"));
// const ChatbotsPage = React.lazy(() => import("./Pages/ChatbotsPage/ChatbotsPage"));
// const CreateAICompanionPage = React.lazy(() => import("./Pages/CreateAICompanionPage/CreateAICompanionPage"));
// const ChatScreenPage = React.lazy(() => import("./Pages/ChatScreenPage/ChatScreenPage"));
// const UserProfile = React.lazy(() => import("./Pages/UserProfilePage/UserProfile"));
// const ExplorePageCategories = React.lazy(() => import("./Pages/CongratulationsPage/CongratulationsPage"));
// const CongratulationsPage = React.lazy(() => import("./Pages/ExplorePageCategories/ExplorePageCategories"));
// const AiGenratePage = React.lazy(() => import("./Pages/AiGenratePage/AiGenratePage"));



const injective = {
  id: 2525,
  name: "Injective",
  // iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
  // iconBackground: '#fff',
  nativeCurrency: { name: "Injective", symbol: "INJ", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://inevm.calderachain.xyz/http"] },
  },

  blockExplorers: {
    default: { name: "Inevm", url: "https://inevm.calderaexplorer.xyz/" },
  },
  testnet: false,
};


const seiDevnet = {
  id: 1328,
  name: "Sei Testnet",
  // iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
  // iconBackground: '#fff',
  nativeCurrency: { name: "Sei", symbol: "SEI", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://evm-rpc-testnet.sei-apis.com/"] },
  },

  blockExplorers: {
    default: { name: "Sei", url: "https://seitrace.com" },
  },
  testnet: true,
};

const coreDaoTestnet = {
  id: 1115,
  name: "Core Chain TestNet",
  network: "Core Chain TestNet",

  nativeCurrency: {
    decimals: 18,
    name: "tCORE",
    symbol: "tCORE",
  },
  rpcUrls: {
    default: { http: ["https://rpc.test.btcs.network/"] },
  },
  blockExplorers: {
    default: {
      name: "CoreDAO Explorer",
      url: "https://scan.test.btcs.network/",
    },
    //   etherscan: { name: "CeloScan", url: "https://celoscan.io" },
  },

  testnet: true,
};


export default function App() {
  const [loading, setLoading] = useState(true);
  const [openSetting, setOpenSetting] = useState(false);
  const userData = useSelector(userDataSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.removeItem("subscription");
  localStorage.removeItem("userData");

  const HandleLoginError = () => {
    const userData = new User();
    dispatch(updateUserData(userData));
  };

  useEffect(() => {
    const browserLanguage = languageOptions?.find(val => val.msLangCode.includes(navigator.language));
    if(browserLanguage)
      dispatch(setChatbotLanguage(browserLanguage))
  }, [navigator.language]);

  // InitializeWagmi();
  useEffect(() => {
    if (isDirty) {
      localStorage.removeItem("subscription");
      localStorage.removeItem("userData");
      DownloadData(dispatch)
        .then(() => {
          DownloadSubInfo(dispatch).catch(console.error);
        })
        .catch(console.error);
      isDirty = false;
    }
  }, [isDirty]);

  // walletConnect
  const { chains, provider } = configureChains(
    [mainnet, polygon, bsc, manta, optimism, base, coreDao, injective, seiDevnet], //coreDAO, optimismSepolia, arbitrumSepolia
    [
      publicProvider({ priority: 0 }),
      // alchemyProvider({ apiKey: import.meta.env.VITE_REACT_APP_ALCHEMY_API_KEY}),

    ],

  );

  const projectId = import.meta.env.VITE_REACT_WALLET_CONNECT_PROJECT_ID;
  const connectors = connectorsForWallets([
    {
      groupName: "Popular",
      wallets: [
        injectedWallet({ projectId, chains }),
        metaMaskWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
        okxWallet({ projectId, chains }),
        rainbowWallet({ projectId, chains }),
        coinbaseWallet({ projectId, chains }),
        trustWallet({ projectId, chains }),
        ledgerWallet({ projectId, chains }),
        argentWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  // dispatch(openOnboarding());
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    try {
      HandleAuth(queryParameters, userData, dispatch, navigate).then(() => {
        HandleObiTransaction().then(() => {
          CheckTransaction(queryParameters);
        });
      });
    } catch (e) {
      HandleLoginError();
    }
  }, [isDirty]);

  useEffect(() => {
    // close the loading animation upon opening the website
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const meta = {
    title: "Orbofi - Your Smart and Personalized Voice AI agents and companions",
    description:
      "The ultimate AI-generated content platform for web3, games, the metaverse, and every online community ",
    canonical: "/images/featured-image.png",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  useEffect(() => {
    // Add route-enter-active class when the component mounts
    const routeElements = document.querySelectorAll(".route");
    routeElements.forEach((element) => {
      element.classList.add("route-enter-active");
    });

    // Clean up function to remove the class when the component unmounts
    return () => {
      routeElements.forEach((element) => {
        element.classList.remove("route-enter-active");
      });
    };
  }, []);

  return (
    <DocumentMeta {...meta}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          coolMode
          chains={chains}
          theme={darkTheme({
            accentColor: "#53aab0",
            accentColorForeground: "white",
          })}
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              <Header setOpenSetting={setOpenSetting} openSetting={openSetting} />
              <LeftSidebar setOpenSetting={setOpenSetting} openSetting={openSetting} />
              <MobileNavigation />
              <AnimatePresence >
                <Suspense>
                  <Routes>
                    <Route path="/" element={<motion.div key="home" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><HomePage /></motion.div>} />
                    <Route path="/Mychatbot" element={<motion.div key="home" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><MychatbotPage /></motion.div>} />
                    <Route path="/explore" element={<motion.div key="explore" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><ExplorePage /></motion.div>} />
                    <Route path="/category/:catName" element={<motion.div key="chatbots" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><CategoriesBigSubcategories /></motion.div>} />
                    <Route path="/createaicompanion" element={<motion.div key="createaicompanion" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><CreateAICompanionPage /></motion.div>} />
                    <Route path="/chat/:chatbotName" element={<motion.div key="/chat/:chatbotName" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><ChatScreenPage setOpenSetting={setOpenSetting} openSetting={openSetting} /></motion.div>} />
                    <Route path="/pricing" element={<motion.div key="pricing" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><PricingPage /></motion.div>} />
                    <Route path="/profile" element={<motion.div key="profile" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><UserProfile isPublicProfile={false} /></motion.div>} />
                    <Route path="/profile/:usename" element={<motion.div key="/profile/:usename" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><UserProfile isPublicProfile={false} /></motion.div>} />
                    <Route path="/user/:usename" element={<motion.div key="/user/:usename" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><UserProfile isPublicProfile={true} /></motion.div>} />
                    <Route path="/Congratulation" element={<motion.div key="Congratulations" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><CongratulationsPage /></motion.div>} />
                    <Route path="/explore/categories" element={<motion.div key="/explore/categories" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><ExplorePageCategories /></motion.div>} />
                    <Route path="/assets/:collectionName/:chainId/:contractAddress/:lZ?" element={<motion.div key="explore" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><AiGenratePage /></motion.div>} />
                    <Route path="/search/:query" element={<motion.div key="/search" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><SearchChatbot /></motion.div>} />
                    <Route path="/search-category" element={<motion.div key="/search-category" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><CategoryChatbots /></motion.div>} />
                    <Route path="/searchSubcategory" element={<motion.div key="/searchSubcategory" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><ChatbotsPageList /></motion.div>} />
                    <Route path="/AllCategoriesBigBox" element={<motion.div key="/AllCategoriesBigBox" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}><AllCategoriesBigBox isExplorePage={true} /></motion.div>} />


                  </Routes>
                </Suspense>
              </AnimatePresence>
            </>
          )}
          <LoginPopUp />
          <OnboardingPopup />
          <Toaster position="bottom-right" />
        </RainbowKitProvider>
      </WagmiConfig>
    </DocumentMeta>
  );
}